import styled from "styled-components"

const Wrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: #E1E1E1;
`

const Content = styled.div`
  position: relative;
  width: 1052px;
  height: 249px;
  margin: auto;
  margin-top: 216px;
  background-color: #FFFFFF;
  text-align: center;
  font-family: 'EnzoOT', sans-serif;
  padding-top: 30px;
  font-size: 30px;

  img {
    position: absolute;
    margin-top: -120px;
    left: 0;
    width: 280px;
  }

  @media (max-width: 1052px) {
    width: 90%;
    img {
      width: 228px;
    }
  }
`

const Success = () => {

    const soleaseLogo = require('../Assets/logo.png')

    return (
        <Wrapper>
            <Content>
                <img alt='' src={soleaseLogo}/>
                <div>
                    <p>Gelukt! Je hebt jouw belafspraak gewijzigd</p>
                </div>
            </Content>
        </Wrapper>
    )
}

export default Success
