import React, { useState } from "react";
import styled from "styled-components";
import Button from "./Button";
import calenderIcon from "../../Assets/Icons/calender-icon.svg";
import clockIcon from "../../Assets/Icons/clock-icon.svg";
import personIcon from "../../Assets/Icons/person-icon.svg";
import phoneIcon from "../../Assets/Icons/phone-icon.svg";

const Wrapper = styled.section`
  background: var(--background-box-color);
  width: 408px;
  height: 613px;
  border-radius: 80px 80px 0px 0px;

  @media (max-width: 1300px) {
    width: 100%;
  }
`;
const StyledTextArea = styled.textarea`
  margin: 10%;
  width: 80%;
  border: none;
  box-sizing: border-box;
  height: 100px;
  resize: none;
  padding: 1% 1%;

  &:focus {
    outline: none;
  }
`;
const StyledNameHeader = styled.h1`
  color: #5a5a5a;
  font-size: 18px;
  margin-top: 30px;
  margin-left: 40px;
`;

const StyledTitle = styled.h1`
  color: black;
  font-size: 28px;
  margin-top: 5%;
  margin-left: 40px;
  font-weight: normal;
`;

const StyledIconSpan = styled.span`
  color: var(--solease-black);
`;

const StyledIconTextWrapper = styled.div`
  padding-top: 20px;
  margin-left: 35px;
  display: flex;
  column-gap: 25px;
`;

const StyledIcon = styled.img`
  vertical-align: Top;
  height: 20px;
  width: 20px;
`;

const InfoBox = ({
  firstname,
  lastname,
  employee,
  phonenumber,
  date,
  starttime,
  endtime,
  title,
  duration,
  onSubmit,
}) => {
  const [notice, setNotice] = useState("");

  return (
    <Wrapper>
      <StyledNameHeader>
        {firstname} {lastname}
      </StyledNameHeader>
      <StyledTitle>{title}</StyledTitle>

      <StyledIconTextWrapper>
        <StyledIcon src={calenderIcon} alt="calender" />
        <StyledIconSpan>
          {date || "----"} om {starttime || "----"} uur
        </StyledIconSpan>
      </StyledIconTextWrapper>

      <StyledIconTextWrapper>
        <StyledIcon src={clockIcon} alt="clock" />
        <StyledIconSpan>{duration || 30} minuten</StyledIconSpan>
      </StyledIconTextWrapper>

      <StyledIconTextWrapper>
        <StyledIcon src={phoneIcon} alt="phone" />
        <StyledIconSpan>Wij bellen jou op: {phonenumber}</StyledIconSpan>
      </StyledIconTextWrapper>

      <StyledIconTextWrapper>
        <StyledIcon src={personIcon} alt="person" />
        <StyledIconSpan>
          Afspraak met: {employee || "Solar adviseur"}
        </StyledIconSpan>
      </StyledIconTextWrapper>

      <StyledTextArea
        placeholder="Als je op een ander nummer gebeld wilt worden of een specifieke vraag hebt, kun je dat hier vermelden."
        value={notice}
        onChange={(e) => setNotice(e.target.value)}
      />

      <Button
        disabled={!date || !starttime}
        handleOnclick={() => date && starttime && onSubmit(notice)}
        buttonText={"AFSPRAAK INPLANNEN"}
      />
    </Wrapper>
  );
};

export default InfoBox;
