import { createGlobalStyle } from "styled-components/macro";
import "./fonts/MuseoSans.css";

export default createGlobalStyle`
 :root {
    --background-box-color: #F5F2ED;
    --solease-blue: #5FBADE;
    --solease-black: #333;

    }

body{  
    height: 100%;
    width: 100%;
    font-family: "MuseoSans", sans-serif;
    letter-spacing: 0.1px;


}

p{}

a{}

textarea::placeholder {font-family: "MuseoSans", sans-serif;}


`;
