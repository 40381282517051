import styled from 'styled-components'
import { TailSpin } from "react-loader-spinner";

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
`

const StyledLoader = () => {
    return (
        <Wrapper>
            <TailSpin color="#00BFFF" height={80} width={80}/>
        </Wrapper>
    )
}

export default StyledLoader
