export const formatDate = (date, slashFormat) => {
    const month = String(date.getMonth() + 1).length === 1 ? `0${date.getMonth() + 1}` : date.getMonth() + 1
    const day = String(date.getDate()).length === 1 ? `0${date.getDate()}` : date.getDate()
    if(slashFormat) return `${day}/${month}/${date.getFullYear()}`
    else return `${date.getFullYear()}-${month}-${day}`
}

export const formatInitialDate = (initialDate) => {
    const buffer = initialDate.split("/")
    const date = new Date(`${buffer[2]}-${buffer[1]}-${buffer[0]}`)

    if(new Date().setHours(0,0,0,0) > date) return undefined
    else return date
}

export const parseInitialTimeslot = (timeslot) => {
    const hour = timeslot.split(":")[0]
    if (hour.length === 2) return `${hour}:00`
    else return `0${hour}:00`
}
