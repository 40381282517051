import React from "react";
import styled from "styled-components";

const StyledButton = styled.a`
  display: inline-block;
  background-color: var(--solease-blue);
  font-size: 1.3rem;
  padding: 0.4rem;
  color: white;
  width: 60%;
  text-align: center;
  margin-left: 20%;
  margin-right: auto;
  cursor: pointer;

  opacity: ${(props) => (props.disabled ? ".5" : "1")};

  :hover {
  }

  @media (max-width: 1300px) {
    width: 75%;
    margin-left: 12.5%;
    margin-right: auto;
  }
`;

const Button = ({ buttonText, handleOnclick, disabled }) => {
  return (
    <StyledButton disabled={disabled} onClick={handleOnclick}>
      {buttonText}
    </StyledButton>
  );
};

export default Button;
