import Calendar from 'react-calendar'
import styled from 'styled-components'

const Wrapper = styled.div`
  padding: 25px;

  .react-calendar {
    &__tile {
      border: none;
      background: transparent;
      height: 40px;
      cursor: pointer;

      &--now, &--active, &:hover {
        position: relative;

        abbr {
          position: relative;
          z-index: 10;
          font-weight: bold;
          transition: all .3s;
        }

        &:before {
          content: "";
          position: absolute;
          top: 0;
          width: 40px;
          height: 40px;
          border-radius: 50%;
          left: 0;
          right: 0;
          margin-left: auto;
          margin-right: auto;
        }
      }

      &--now {
        abbr {
          color: #5FBADE;
        }

        &:before {
          background-color: white;
        }
      }

      &:hover {
        abbr {
          color: #5FBADE;
        }

        &:before {
          background-color: white;
        }
      }

      &--active {
        abbr {
          color: white !important;
        }

        &:before {
          background-color: #5FBADE !important;
        }
      }
    }

    &__month-view {
      &__weekdays__weekday {
        text-align: center;
        border: none;
        margin-bottom: 10px;

        abbr {
          text-decoration: none;
          font-size: 13px;
          font-weight: 600;
        }
      }

      &__days__day--neighboringMonth {
        opacity: .2;
      }
    }

    &__navigation {
      display: flex;
      justify-content: center;
      margin-bottom: 40px;

      &__label {
        border: none;
        background: transparent;
        flex-grow: 0 !important;
        pointer-events: none;
        margin: 0 50px;
        font-weight: bold;
        font-size: 14px;
        width: 150px;
      }

      &__arrow {
        border: none;
        background: transparent;
        cursor: pointer;
        margin: 0;
        font-size: 23px;
        padding-bottom: 3px;
      }
    }
  }

  .react-calendar__navigation__next2-button, .react-calendar__navigation__prev2-button {
    display: none;
  }
`

const StyledCalendar = ({onDateChanged, initialDate}) => {

    return (
        <Wrapper>
            <Calendar defaultValue={initialDate}
                      onChange={onDateChanged}
                      calendarType='US'
                      tileDisabled={({ date }) => date.getDay() === 0 || date.getDay() === 6}
                      minDate={new Date()}/>
        </Wrapper>
    )
}

export default StyledCalendar
