import React, {useEffect, useState} from "react";
import styled from "styled-components";
import DateTimeBox from "./Calendar/DateTimeBox";
import InfoBox from "./InfoBox/InfoBox";
import {useParams} from "react-router-dom";
import {getRequest, postRequest} from "../utils/api";
import StyledLoader from "./StyledLoader";
import Error from "./Error";
import {
    formatDate,
    formatInitialDate,
    parseInitialTimeslot,
} from "../utils/helperFunctions";
import {DateTime} from "luxon";
import Success from "./Success";

const Wrapper = styled.div`
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;

  @media (max-width: 1300px) {
    height: unset;
    width: 95%;
    flex-direction: column;
    margin: auto;
  }
`;

const CallAppointment = () => {
    const [loading, setLoading] = useState(true);
    const [data, setData] = useState();
    const [error, setError] = useState(false);
    const [date, setDate] = useState();
    const [timeslot, setTimeslot] = useState();
    const [success, setSuccess] = useState(false)

    const {customerId, appointmentId} = useParams();

    useEffect(() => {
        const fetchData = () => {
            setLoading(true);
            const url = `/appointment/${appointmentId}/${customerId}`;
            getRequest(
                url,
                {},
                (data) => {
                    setData(data);
                    setLoading(false);
                },
                (error) => {
                    setError(true);
                    setLoading(false);
                }
            );
        };
        fetchData();
    }, [customerId, appointmentId]);

    useEffect(() => {
        if (data) {
            const date = formatInitialDate(data.start_date);
            if (date) {
                setDate(date);
                setTimeslot(parseInitialTimeslot(data.start_time));
            }
        }
    }, [data]);

    const onSubmit = (comment) => {
        setLoading(true);
        const appointmentDateTime = DateTime.fromJSDate(date)
            .setZone("utc").plus({hour: 2})
            .set({
                hour: Number(timeslot.split(":")[0]),
                minute: 0
            })

        postRequest(
            `/submit`,
            {
                appointmentId,
                customer_id: customerId,
                starttime: appointmentDateTime.toMillis(),
                endtime: appointmentDateTime.set({minutes: 30}).toMillis(),
                employee: data.employee_id,
                comment: comment,
            },
            (response) => {
                setLoading(false);
                setSuccess(true)
            },
            (error) => {
                console.log(error);
            }
        );
    };

    if (loading) return <StyledLoader/>;
    else if (error) return <Error/>;
    else if (success) return ( // this screen is temporary until redesign
        <Wrapper>
            <Success/>
        </Wrapper>
    )
    else return (
        <Wrapper>
            <DateTimeBox
                initialDate={date}
                setDate={setDate}
                setTimeslot={setTimeslot}
                timeslot={timeslot}
                employeeId={data.employee_id}
            />
            <InfoBox
                firstname={data.customer_firstname}
                lastname={data.customer_lastname}
                title={data.title}
                starttime={timeslot}
                endtime={data.end_time}
                date={date ? formatDate(date, true) : null}
                phonenumber={data.phonenumber}
                employee={data.employee}
                onSubmit={onSubmit}
            />
        </Wrapper>
    );
};

export default CallAppointment;
