import Axios from "axios";

const host = window.location.hostname;
const isProduction =
  host === "www.call-appointment.app.solease.nl" ||
  host === "call-appointment.app.solease.nl";
const apiUrl = isProduction
  ? `https://api.solease.nl/callappointment`
  : `https://staging.api.solease.nl/callappointment`;

const getRequest = (url, params, onSuccess, onError) => {
  Axios.get(apiUrl + url, { params: params })
    .then((res) => {
      onSuccess(res.data, res.status);
    })
    .catch((error) => onError(error));
};

const postRequest = (url, body, onSuccess, onError) => {
  Axios.post(apiUrl + url, body)
    .then((res) => {
      onSuccess(res.data);
    })
    .catch((error) => onError(error));
};

export { getRequest, postRequest };
