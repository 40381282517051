import styled, {css} from 'styled-components'

const Wrapper = styled.div`
  max-width: 515px;
  margin: auto;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  margin-top: 69px;
  @media (max-width: 575px){
    margin-top: 10px;
    width: 90%;
  }
`

const Timeslot = styled.div`
  width: 95px;
  height: 40px;
  background: white;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 19px;
  cursor: ${props => props.disabled ? 'default' : 'pointer'};
  color: ${props => props.disabled ? '#C7C7C7' : '#666666'};
  transition: all .4s;
  
  ${props => props.active && css`
    background: #5FBADE;
    color: white;
  `}

  &:hover {
    background-color: ${props => props.disabled ? 'none' : '#5FBADE'};
  }

  @media (max-width: 575px){
    width: 18%;
    font-size: 14px;
  }
`

const Timeslots = ({disabledTimeslots, setTimeslot, activeTimeslot}) => {

    const timeslots = [
        {label: "9:00", disabled: disabledTimeslots.includes(9)},
        {label: "10:00", disabled: disabledTimeslots.includes(10)},
        {label: "11:00", disabled: disabledTimeslots.includes(11)},
        {label: "12:00", disabled: disabledTimeslots.includes(12)},
        {label: "13:00", disabled: disabledTimeslots.includes(13)},
        {label: "14:00", disabled: disabledTimeslots.includes(14)},
        {label: "15:00", disabled: disabledTimeslots.includes(15)},
        {label: "16:00", disabled: disabledTimeslots.includes(16)},
        {label: "17:00", disabled: disabledTimeslots.includes(17)},
        {label: "18:00", disabled: disabledTimeslots.includes(18)},
    ]

    return (
        <Wrapper>
            {timeslots.map((timeslot, index) =>
                <Timeslot
                    key={index}
                    onClick={() => !timeslot.disabled && setTimeslot(timeslot.label)}
                    active = {activeTimeslot === timeslot.label}
                    disabled={timeslot.disabled}>
                    {timeslot.label}</Timeslot>)}
        </Wrapper>
    )
}

export default Timeslots
