import {BrowserRouter as Router, Route, Routes} from 'react-router-dom'
import CallAppointment from './Components/CallAppointment'
const App = () => {
  return (
    <div className="App">
      <Router>
        <Routes>
          <Route path='/:appointmentId/:customerId' element={<CallAppointment/>}/>
        </Routes>
      </Router>
    </div>
  );
}

export default App;
