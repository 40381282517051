import styled from 'styled-components'
import StyledCalendar from './StyledCalendar'
import Timeslots from "./Timeslots";
import {useState} from "react";
import {getRequest} from "../../utils/api";
import {formatDate} from "../../utils/helperFunctions";

const Wrapper = styled.div`
  background: var(--background-box-color);
  margin: 25px;
  width: 804px;
  height: 613px;
  border-radius: 80px 80px 0px 0px;
  
  @media (max-width: 1300px){
    width: 100%;
    height: unset;
  }
`
const range = (min, max) => [...Array(max - min + 1).keys()].map(i => i + min)

const parseTimeslots = (timeslots) => {
    const disabledTimeslots = timeslots.reduce((disabledTimeslots, timeslot) => {
        const start = Number(timeslot.starttime.split(":")[0])
        const end = Number(timeslot.endtime.split(":")[0])
        return [...disabledTimeslots, ...range(start, end)]
    }, [])
    return [...new Set(disabledTimeslots)]
}

const DateTimeBox = ({setDate, setTimeslot, employeeId, initialDate, timeslot}) => {

    const [disabledTimeslots, setDisabledTimeslots] = useState([])

    const onDateChanged = (date) => {
        setDate(date)
        setTimeslot()
        const url = `/timeslot/${formatDate(date)}/${employeeId}`
        getRequest(url, {}, (data) => {
            setDisabledTimeslots(parseTimeslots(data))
        }, (error) => {
            console.log('error...', error)
        })
    }

    return (
        <Wrapper>
            <StyledCalendar onDateChanged={onDateChanged} initialDate={initialDate}/>
            <Timeslots activeTimeslot={timeslot} disabledTimeslots={disabledTimeslots} setTimeslot={setTimeslot}/>
        </Wrapper>
    )
}

export default DateTimeBox
